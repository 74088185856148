<template>
  <v-card class="pa-2">
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="festivo.name"
            label="Nombre"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="festivo.localidad"
            label="Localidad / Área"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-subheader>Inicio y fin del festivo</v-subheader>
          <v-divider class="mb-2"></v-divider>
          <v-date-picker
            :first-day-of-week="1"
            full-width
            filled
            dense
            hide-details="auto"
            color="primary"
            range
            v-model="festivo.range"
            :rules="[rules.req]"
          ></v-date-picker>
          <span v-if="festivo.range.length == 0" class="error--text">
            <v-icon small left color="error">mdi-alert-outline</v-icon>
            Este campo es obligatorio
          </span>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="festivo.idFestivo" @click.stop="$emit('reload')">
							<v-icon left>mdi-close</v-icon>
												Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ festivo.idFestivo ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="confirm" />

  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
  },
	components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
	},
  data() {
    return {
      festivo: {
        ...this.value,
        range: [this.value.start, this.value.end].filter((x) => x),
      },
      rules: {
        req,
      },
    };
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

			const confirmation = await this.$refs.confirm.open(
				`Festivo: ${this.festivo.name}`,
        `¿Estás seguro de que quieres ${this.festivo.idFestivo ? "actualizar" : "crear"} el festivo?`,
        { color: "primary" }
      );
      if (!confirmation) return;


      await axios({
        method: this.festivo.idFestivo ? "PUT" : "POST",
        url: `/festivos/${String(
          this.festivo.idFestivo ? this.festivo.idFestivo : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.festivo,
          start: this.festivo.range[0],
          end: this.festivo.range[1] || this.festivo.range[0],
        },
      });

      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>